import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { IconLibrary } from '../../../components/IconLibrary';
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconografia",
      "style": {
        "position": "relative"
      }
    }}>{`Iconografia`}</h1>
    <TabLinks items={[{
      title: 'Utilização',
      href: '/design-guidelines/iconography'
    }, {
      title: 'Biblioteca',
      href: '/design-guidelines/iconography/library'
    }]} mdxType="TabLinks" />
    <IconLibrary mdxType="IconLibrary" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      